import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, productName, theme) {
  return {
    fontWeight:
      productName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DinnerSetter = () => {
    const [products, setproducts] = useState([]);
    const theme = useTheme();
    const [productName, setProductName] = React.useState([]);

    const { isLoading: isLoadingproducts, refetch: getproducts } = useQuery(
        "products",
        async () => {
          return await apiClient.get("/api/products");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setproducts(res.data.products);
                let dinners = res.data.dinners;
                let selected = [];
                if (dinners.length > 0) {
                  dinners.forEach(dinner => {
                    selected.push(dinner.product.name);
                  });
                  setProductName(selected);
                }
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const dispatch = useDispatch();
    const [error, setErros] = useState('');
    const { isLoading: isSendingRequest, mutate: postDinner } = useMutation(

        async () => {
          return await apiClient.post(`/api/store-dinner`, {
            dinner: productName
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            setproducts(res.data.products);
                let dinners = res.data.dinners;
                let selected = [];
                if (dinners.length > 0) {
                  dinners.forEach(dinner => {
                    selected.push(dinner.product.name);
                  });
                  setProductName(selected);
                }
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postDinner();
      } 

      useEffect(() => {
        getproducts();
      }, []);

      

      const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setProductName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        USE THE FORM TO UPDATE DATA (18+)
                        </Typography>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {/* description */}
                        </Typography>

                        {/* start select */}
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <InputLabel id="demo-multiple-chip-label">Product List</InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={productName}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Product List" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {products.length > 0 && products.map((product) => (
                              <MenuItem
                                key={product.id}
                                value={product.name}
                                style={getStyles(product.name, productName, theme)}
                              >
                                {product.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* end select */}

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Setting...' : 'Set dinner'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default DinnerSetter
