import React from 'react'
import PackageSlides from './PackageSlides';
import MobileMenu from './MobileMenu';
import TodayBreakFast from './TodayBreakFast';
import TodayLunch from './TodayLunch';
import TodayDinner from './TodayDinner';
import FoodPlans from '../Subscriptions/FoodPlans';
import ListCars from '../Cars/ListCars';
import SearchButton from '../Search/SearchButton';

const Index = () => {
  return (
    <>
    <PackageSlides/>
    <MobileMenu/>
    <SearchButton/>
    <TodayBreakFast/>
    {/* <ListCars/> */}
    <TodayLunch/>
    <TodayDinner/>
    {/* <FoodPlans/> */}
    </>
  )
}

export default Index
