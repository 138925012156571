import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CreateCategories = () => {
    const [categories, setcategories] = useState(null);
    const { isLoading: isLoadingcategories, refetch: getCategories } = useQuery(
        "categories",
        async () => {
          return await apiClient.get("/api/categories");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setcategories(res.data.categories);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialCategory = {
      name: '',
      description: '',
      show_area: ''
    }
    
    const [error, setErros] = useState('');
    const [categoryForm, setcategoryForm] = useState(initialCategory);

    const onChange = (e) =>
    setcategoryForm({ ...categoryForm, [e.target.name]: e.target.value });

    const initialImage = {
      image: ''
  }
  const [productImage, setImage] = useState(initialImage);
  const  onFileChange = (event) => { 
      setImage({...productImage, image: event.target.files[0]});
    }; 


    const { isLoading: isSendingRequest, mutate: postCategory } = useMutation(

        async () => {

          const formData = new FormData();
          formData.append('name', categoryForm.name);
          formData.append('description', categoryForm.description);
          formData.append('show_area', categoryForm.show_area);
          formData.append('product_image', productImage.image);      
          return await apiClient.post(`/api/store-category`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postCategory();
      } 

      useEffect(() => {
        getCategories();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE PRODUCT CTEGORIES
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create categories
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Category Name">Category Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Category Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Category Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="description">Description</InputLabel>
                            {(error !== '' && error.description) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="description"
                            name="description"
                            type={'text'}
                            label="Description"
                            helperText={error.description}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="description"
                            name="description"
                            type={'text'}
                            label="Description"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.description}
                            </Typography>
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="show_area">Select Where To Display</InputLabel>
                            {(error !== '' && error.show_area) ?
                            <Select
                            error
                            onChange={onChange}
                            labelId="show_area"
                            id="show_area"
                            name="show_area"
                            label={`Select Where To Display`}
                            helperText={error.show_area}
                            >
                            <MenuItem selected value=''>
                                select Where to display
                            </MenuItem>              
                            <MenuItem value="Slider">Slider </MenuItem>
                            <MenuItem value="Sidebar">Sidebar</MenuItem>
                        </Select>
                            :
                            <Select
                                onChange={onChange}
                                labelId="show_area"
                                name="show_area"
                                id="show_area"
                                label={`Select Where To Display`}
                                >
                            <MenuItem selected value=''>
                                select Where to display
                            </MenuItem>              
                            <MenuItem value="Slider">Slider </MenuItem>
                            <MenuItem value="Sidebar">Sidebar</MenuItem>
                            </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.show_area}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Upload Category Image
                        </Typography>
                        <Box>
                        {productImage.image !== '' &&
                            <img style={{width: "180px", height: "auto"}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.product_image) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="product_image"
                            type='file'
                            name="product_image"
                            helperText={error.product_image}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="product_image"
                            type='file'
                            name="product_image"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.product_image}
                            </Typography>
                        </FormControl>
                        
                    </CardContent> 

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create category'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateCategories
