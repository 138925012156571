import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Plate from '../Plates/Plate';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setProduct } from '../Products/productSlice';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';

const CategoryProducts = () => {
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const params = useParams();
    const categories = useSelector((state) => state.products.categories)

    const  getRandomArbitrary = (min, max) => {
      return (Math.random() * (max - min) + min).toFixed(1);
  }

    const [products, setproducts] = useState([]);

    useEffect(() => {
      const items = JSON.parse(localStorage.getItem('products'));
      if (items) {
        setproducts(items);
      }

      window.addEventListener('updatedStorage', function (e) {
        const items = JSON.parse(localStorage.getItem('products'));
        if (items) {
          setproducts(items);
        }
    }, false); 
    

    }, [params]);

  return (
    <div style={{marginTop: '70px'}}>
    <Typography mb={1} ml={3} mt={4} sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        {params.category}
    </Typography>
    
      {categories.map(category =>(
        category.name === params.category && 
        <Typography ml={3} mt={1} sx={{textAlign: 'left'}} variant="caption" component="div" gutterBottom>
           {category.description}
           </Typography>
      ))}
   

        {products.filter(item => item.category.name === params.category).length < 1 &&
            <Box mx={2} mt={10}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">Sorry we currently do not have any {params.category} for now</Alert>
            </Stack>
            </Box>
        }
    {products.length > 0 &&
    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {products.map(product=>(
        product.category.name === params.category &&
        <Grid key={product.id} item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link onClick={()=> {dispatch(setProduct(product)); scrolltoTop()}}  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia
                component="img"
                sx={{ width: '100%' }}
                image={product.product_image}
                alt={product.description}
            />
            <CardContent >            
            <Typography sx={{ fontWeight: 'bold', fontSize: 16  }} gutterBottom variant="subtitle2" component="div">
                {product.name}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                {product.short_description}
            </Typography>
            
            </CardContent>
            <Typography color="secondary" sx={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1   }} gutterBottom variant="button" component="span">
            {formatPrice(product.unit_price)}
            </Typography>
          </Link>
            <CardActions sx={{justifyContent: 'center'}}>
            <Plate product={product} />
            </CardActions>    
        </Card>
    </Grid>
      ))}
</Grid>
    }
    
</div>
  )
}

export default CategoryProducts
