import React, {useState, useEffect, Fragment} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardActions } from '@mui/material';
import { useQuery } from 'react-query';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';

import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation } from 'react-query';
import { setFundings } from './walletSlice';
import { setDinings } from '../../request/Dining/diningSlice';
import { emptyCart } from '../Plates/plateSlice';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WalletButton = (props) => {
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const {content,
    subscription_id,
    amount1,
    phone,
    address,
    other_details} = props

    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const addresses = useSelector((state) => state.products.addresses);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const [coin, setCoin] = useState('');
    const contents = useSelector((state) => state.plate.items);

    const dispatch = useDispatch();
    const [amount, setAmount] = useState(amount1);
    const [buttonText, setbuttonText] = useState(null);
    const [amountText, setAmountText] = useState(null);
    const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {

       
    if (isAuth && user.name) {
      setOpen(true);
    }else{
      let path = window.location.pathname;
      dispatch(setRedirect(path));
      navigate('/login');
    }
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const { isLoading: isSendingRequest, mutate: postRequest } = useMutation(

    async () => {
      return await apiClient.post(`/api/save-order`, {
        content: content,
      subscription_id: subscription_id,
      amount: amount1,
      phone: phone,
      address: address,
      other_details: other_details
      });
    },
    {
      onSuccess: (res) => {
        dispatch(setDinings(res.data.dinings));
        contents.forEach(product => {
          dispatch(emptyCart(product));
        });
        // dispatch(setFundings(res.data.fundings))
        handleClose();
        setCoin('');

        localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
        const event = new Event('newMessage');
        window.dispatchEvent(event);
        const event2 = new Event('processed');
        window.dispatchEvent(event2);
        setTimeout(() => {
          navigate('/dining');
        }, 2000);
      },
      onError: (err) => { 
        handleClose();
        setCoin('');
        if (err.response?.data.length) {
          let myerror = err.response?.data || err;         
          // setErros(myerror.errors)  
          
          const event2 = new Event('processed');
          window.dispatchEvent(event2); 
        }else{
          
          let errMessage = err.response?.data.message || err;     

          localStorage.setItem('notification', JSON.stringify({message : `${errMessage}`, 'type': 'error' }));
          const event = new Event('newMessage');
          window.dispatchEvent(event);
          const event2 = new Event('processed');
          window.dispatchEvent(event2); 
        }
            
      },
    }
  );


  return (
    <div style={{width: '100%'}}>
      
      <Button fullWidth={true}  onClick={handleClickOpen} variant="contained" size='large' color="primary">
      Checkout
      </Button>

              
      <Dialog
        sx={{zIndex: 40000}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>

          {coin === '' ?
          <Fragment>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
          Kindly select any payment method to pay {formatPrice(amount1)}
          </DialogContentText>

          {/* <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount To Fund</InputLabel>
              <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount To Fund"
              />
            </FormControl>
          </Box>  */}

          {addresses.length > 0 &&
            
      <List sx={{ width: '100%', marginTop: '20px', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader sx={{zIndex: 0, textAlign: 'center', position: 'relative'}}>SELECT ANY PAYMENT METHOD BELOW</ListSubheader>}
      >

      {addresses.map(coin => (
         <Fragment key={coin.id}>
        <ListItem alignItems="flex-start">
        <ListItemText
          primary={coin.coin_name}
          secondary={
            <React.Fragment>
            </React.Fragment>
          }
        />
        <Button onClick={() => {
          if (amount1 < 1) {
            setCoin('');
          }else{
            setCoin(coin);
          }
          
        
        }} fullWidth={false} edge="end" size="small" color="primary" variant="contained"> 
          Pay
        </Button> 
        
      </ListItem>
      <Divider  component="li" />
     </Fragment>
      ))}
    </List>
          }   
         </Fragment> 
        :
        <Card sx={{ borderRadius: '9px', height: '100%', margin: 0}}>

          <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
          Kindly follow the instruction below to complete your payment using {coin.coin_name}
          </Typography>

          <CardContent sx={{textAlign: 'center'}}>

        {/* <QRCode qrStyle="dots" value={coin.address} size={200} /> */}

            <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />           
            </Box>
           
          <CopyToClipboard onCopy={() => {setbuttonText('payment details copied');
              setTimeout(() => {
                  setbuttonText(null);
              }, 2000);}} text={coin.address}>
          <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <TextField
              minRows={2} multiline              
              defaultValue={coin.address}
              id="address"
              name="address"
              type={'text'}
              label= {buttonText !== null? buttonText: `payment details`}
              />
          </FormControl> 
          </CopyToClipboard>

          <CopyToClipboard onCopy={() => {setAmountText('Amount Copied!');
              setTimeout(() => {
                setAmountText(null);
              }, 2000);}} text={amount1}>
          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
              <InputLabel htmlFor="coinAmount">{amountText !== null? amountText: `Click to copy amount`}</InputLabel>
              <OutlinedInput
              disabled
              id="coinAmount"
              defaultValue={amount1}
              name="coinAmount"
              type={'number'}
              label={amountText !== null? amountText: `Click to copy amount`}
              />              
          </FormControl> 
          </CopyToClipboard>         
        
          </CardContent>

          <CardActions> 
          <Button onClick={()=>setCoin('')} fullWidth={true} edge="end" size="small" color="error" variant="contained"> 
          Cancel
        </Button> 
        <Button onClick={postRequest} fullWidth={true} edge="end" size="small" color="primary" variant="contained"> 
         {isSendingRequest? 'sending...' : 'I have Paid'} 
        </Button> 
          </CardActions>
        </Card>
        
        }
        </DialogContent>
        
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
      <CircularProgress color="inherit" />
      <Typography my={2} variant="body2" color="success">
          Processing Payment...!
      </Typography>
      </Backdrop>
    </div>
  )
}

export default WalletButton
