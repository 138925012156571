import * as React from 'react';
import { useEffect, useState, Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import SecondTimer from '../Plates/SecondTimer';

export default function Dining() {
  const dispatch = useDispatch();
  const getDate = (date) => new Date(date).toDateString();
  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const dinings = useSelector((state) => state.dinings.dinings);
  const [open, setOpen] = useState(0);  
  const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'short' });
  

  return (
    <div style={{marginTop: '70px', marginBottom: '20px'}}>
      <Container>
      <Typography  mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        Your Booking History
      </Typography>
      {dinings.length < 1 && 
      <Box mx={2} mt={10}>
      <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="info">No history to show, place orders now to get started. Thank you.</Alert>
      </Stack>
      </Box>
      }
    <TableContainer component={Paper}>
      {dinings.length > 0 &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell/>
            
            <TableCell align="right">Amount Paid</TableCell>
            <TableCell align="right">Client Contact</TableCell>
            <TableCell align="right">Client Address</TableCell>
            <TableCell align="right">Extra Info</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Time Spent</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {dinings.map((plate) => (
        <Fragment key={plate.id}>
          <TableRow
            key={plate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          ><TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {open === plate.id ? setOpen(0): setOpen(plate.id) } }
          >
            {open === plate.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        
            
            <TableCell align="right">{formatPrice(plate.amount)}</TableCell>
            <TableCell align="right">{plate.phone}</TableCell>
            <TableCell align="right">{plate.address}</TableCell>
            <TableCell align="right">{plate.other_details}</TableCell>
            <TableCell align="right"><span style={{color: `${(plate.status === 'confirmed' && '#009801') || (plate.status === 'pending' && '#eea507') || (plate.status === 'delivered' && '#c5d8c5')|| (plate.status === 'declined' && '#dd1b0c')|| (plate.status === 'on the way' && '#0a610a') }`}}>{plate.status}</span></TableCell>
            <TableCell align="right">{getDate(plate.created_at)}</TableCell>  
            <TableCell align="right"><SecondTimer time={plate.created_at}/></TableCell>  
                      
          </TableRow>


          <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === plate.id} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6"  gutterBottom component="div">
                Booking Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Escort Name</TableCell>
                    <TableCell align="left">Rate</TableCell>
                    <TableCell align="left">Image</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {JSON.parse(plate.content).map((product) => (
                    <TableRow key={product.id}>
                      <TableCell align="left" component="th" scope="row">{product.name}</TableCell>
                      <TableCell align="left">{formatPrice(product.unit_price)}</TableCell>
                      <TableCell align="left"><Avatar alt={product.name} src={product.product_image} variant="square"/></TableCell>
                      <TableCell align="left"><span style={{color: `${(product.status === 'confirmed' && '#009801') || (product.status === 'pending' && '#eea507') || (product.status === 'delivered' && '#c5d8c5') || (product.status === undefined && '#eea507') || (product.status === 'on the way' && '#085b08') || (product.status === 'declined' && '#dd1b0c') }`}}>{product.status === undefined ? plate.status :  product.status}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}