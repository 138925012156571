import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';


export default function UserFundings(props) {
  const {transactions} = props;
  const getDate = (date) => new Date(date).toDateString();
  const formatPrice = (amount) => new Intl.NumberFormat().format(amount);

  return (
    <div>
      {transactions &&
    <TableContainer component={Paper}>
      {transactions.length > 0 &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Amount</TableCell>
          <TableCell align="right">Gateway</TableCell>
          <TableCell align="right">Reference</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((funding) => (
          <TableRow
            key={funding.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            ${formatPrice(funding.amount)}
            </TableCell>
            <TableCell align="right">{funding.gateway}</TableCell>
            <TableCell align="right">{funding.transaction_id}</TableCell>
            <TableCell color={funding.status === 'successful'? 'success': 'error'} align="right">{funding.status}</TableCell>
            <TableCell align="right">{getDate(funding.created_at)}</TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    }

    </div>
  );
}