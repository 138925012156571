import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setDinings } from './diningSlice';
import { setAddresses } from '../Products/productsSlice';

export const LoadDining = () => {
    const dispatch = useDispatch();
    const { isLoading: isLoadingPlates, refetch: getPlates } = useQuery(
        "dinings",
        async () => {
          return await apiClient.get("/api/view-order");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setDinings(res.data.plates))
              dispatch(setAddresses(res.data.addresses))

                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getPlates();
      }, []);
 
}

export default LoadDining
