import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { setFeatured } from '../Featured/featuredSlice';
import { useDispatch , useSelector} from 'react-redux';
import { setProduct } from '../Products/productSlice';

const PackageSlides = () => {
  const dispatch = useDispatch();
  const scrolltoTop = () => window.scrollTo(0, 0);
  const featureds = useSelector((state) => state.featured.featureds);

    const settings = {
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


  return (
        <Card sx={{marginTop: '65px', paddingX: '10px'}} elevation={0}>
          {featureds.length > 0 &&
          <Slider {...settings}>
          {featureds.map(featured=>(
           featured.product.show_area === 'Slider'&&
        <Link key={featured.product.id} onClick={()=> {scrolltoTop()}}  to={`/category/${featured.product.name}`} component={RouterLink} underline="none" color="inherit">
              <Card sx={{marginX: '5px', borderRadius: '10px'}}>
                  <CardMedia
                          component="img"
                          sx={{ width: '100%', height: 200 }}
                          image={featured.product.image}
                          alt={featured.product.name}
                      />
                      <Typography px={2} mb={0} mt={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(6, 6, 6, 0.64)', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff', borderBottomRightRadius: '5px', borderBottomRightRadius: '5px' }} gutterBottom variant="subtitle2" component="div">
                      
                      <Typography sx={{color: '#fff', fontWeight: 'bold', fontSize: 18}}  variant="h5" color="text.secondary">
                          {featured.product.name} 
                      </Typography>
                      </Typography>
              </Card>
          </Link>
          ))}
          
        </Slider>
          }
      
      </Card>
  )
}

export default PackageSlides
