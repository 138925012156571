import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import WcIcon from '@mui/icons-material/Wc';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import DeblurIcon from '@mui/icons-material/Deblur';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import DuoIcon from '@mui/icons-material/Duo';
import WomanIcon from '@mui/icons-material/Woman';

export default function Footer() {
  const items = useSelector((state) => state.plate.items);
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/category/White" label="White" icon={<PeopleOutlineIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/For Women" label="For Women" icon={<WomanIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/Gay" label="Gay" icon={<SocialDistanceIcon />} />
          <BottomNavigationAction component={RouterLink} to="/category/Black" label="Black" icon={
          <PeopleAltIcon />} />
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
