import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WineBarIcon from '@mui/icons-material/WineBar';
import AppleIcon from '@mui/icons-material/Apple';
import CookieIcon from '@mui/icons-material/Cookie';
import TapasIcon from '@mui/icons-material/Tapas';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import Badge from '@mui/material/Badge';
import DiningIcon from '@mui/icons-material/Dining';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CarCrashIcon from '@mui/icons-material/CarCrash';


import PsychologyIcon from '@mui/icons-material/Psychology';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

import SpaIcon from '@mui/icons-material/Spa';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DuoIcon from '@mui/icons-material/Duo';

import WcIcon from '@mui/icons-material/Wc';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import DeblurIcon from '@mui/icons-material/Deblur';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import BookIcon from '@mui/icons-material/Book';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import WomanIcon from '@mui/icons-material/Woman';
import Avatar from '@mui/material/Avatar';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { green, pink } from '@mui/material/colors';

export default function Offline() {

  const items = useSelector((state) => state.plate.items);
  const categories = useSelector((state) => state.products.categories)
  return (
        <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Massage Service' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <SpaIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Massage Service`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/category/Travel Escort' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <ConnectingAirportsIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Travel Escort`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/18+' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/ds7itsztm/image/upload/v1729586035/18pluswhite_yf9sza.png' sx={{ bgcolor: pink[500], width: 22, height: 22}}>
        {/* <FavoriteIcon sx={{fontSize: '48px', color: '#fff'}}/> */}
      </Avatar>
                  </ListItemIcon>
                  <ListItemText  primary={`18+`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Online Companion' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <DuoIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Online Companion`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/category/For Women' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <WomanIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`For Women`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Gay' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <SocialDistanceIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Gay`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/White' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <PeopleOutlineIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`White`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/category/Black' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <PeopleAltIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Black`} />
                  </ListItemButton>
              </ListItem>
            </Link>
                     
                     

            

            

            
          </List>

          <Divider />
          <List>
          
          <Link  to='/plate-content' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Badge badgeContent={items.length} color="error">
                      <BookIcon />
                  </Badge>
                  </ListItemIcon>
                  <ListItemText  primary={`Book`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            

            <Link  to='/login' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <LoginIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Login`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Divider />

            {categories.length > 0 &&
            categories.map(category =>(
              category.show_area === 'Sidebar' &&
              <Fragment key={category.id}>
              <Link  to={`/category/${category.name}`} component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    {/* <ListItemIcon>
                    <CarCrashIcon/>
                    </ListItemIcon> */}
                    <ListItemText  primary={category.name} />
                    </ListItemButton>
                </ListItem>
              </Link>
              </Fragment>

            ))
            }
            <Divider/>
          </List>
    </Fragment>
  );
}