import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: JSON.parse(localStorage.getItem('products')) || [],
    breakfasts: JSON.parse(localStorage.getItem('breakfasts')) || [],
    lunches: JSON.parse(localStorage.getItem('lunches')) || [],
    dinners: JSON.parse(localStorage.getItem('dinners')) || [],
    categories: JSON.parse(localStorage.getItem('categories')) || [],
    addresses: JSON.parse(localStorage.getItem('addresses')) || []

}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state, action) =>{
            state.products = action.payload;  
            localStorage.setItem('products', JSON.stringify(state.products));
        },

        setCategories: (state, action) =>{
            state.categories = action.payload;  
            localStorage.setItem('categories', JSON.stringify(state.categories));
        },
        
        setBreakfasts: (state, action) =>{
            state.breakfasts = action.payload;  
            localStorage.setItem('breakfasts', JSON.stringify(state.breakfasts));
        },

        setLunches: (state, action) =>{
            state.lunches = action.payload;  
            localStorage.setItem('lunches', JSON.stringify(state.lunches));
        },

        setDinners: (state, action) =>{
            state.dinners = action.payload;  
            localStorage.setItem('dinners', JSON.stringify(state.dinners));
        },

        setAddresses: (state, action) => {
            state.addresses = action.payload;  
            localStorage.setItem('addresses', JSON.stringify(state.addresses));
        }
    }
})

export const {setProducts, setAddresses, setBreakfasts, setLunches, setDinners, setCategories } = productsSlice.actions;
export default productsSlice.reducer;