import React, {useState, useEffect, Fragment} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardMedia from '@mui/material/CardMedia';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MessageIcon from '@mui/icons-material/Message';

export const OrderReceipt = React.forwardRef((props, ref) => {
    const {order} = props;
    const formatPrice = (amount) => new Intl.NumberFormat().format(amount);
    const getDate = (date) => new Date(date).toDateString();

  return (
    <div ref={ref}>
        {order !== null && 
      <Card sx={{backgroundColor: '#ffffff'}}>
        <Box sx={{textAlign: 'center', marginTop: '8px', display: 'flex', justifyContent: 'center'}}>
        <img style={{height: "60px"}} alt="Elegant Icon" src="https://res.cloudinary.com/ds7itsztm/image/upload/v1728894817/elegantescort-pink-500_h8fhxl.png" />
        </Box>
        

    <CardContent>

    <List>
        <Typography mt={2} sx={{ textAlign: 'center', fontWeight: 'bolder', color: '#000000', fontSize: 18  }} gutterBottom variant="subtitle2" component="div">
                CLIENT DETAILS
        </Typography>
        <Divider sx={{backgroundColor: '#1f211f'}}/>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon sx={{color: '#000000'}}>
                                    <AccountCircleIcon sx={{color: '#000000'}} />
                                </ListItemIcon>
                                <ListItemText  primary={<Typography sx={{fontWeight: 'bolder', color: '#000000'}}>{order.user.name}</Typography>}
                                secondary={<Typography sx={{color: '#1f211f', fontWeight: 'bold'}}>Customer Name</Typography>}
                                 />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PhoneIcon sx={{color: '#000000'}} />
                                </ListItemIcon>
                                <ListItemText primary={<Typography sx={{fontWeight: 'bolder', color: '#000000'}}>{order.phone}</Typography>} secondary={<Typography sx={{color: '#1f211f', fontWeight: 'bold'}}>Client Phone</Typography>} />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <LocationOnIcon sx={{color: '#000000'}}/>
                                </ListItemIcon>
                                <ListItemText primary={<Typography sx={{fontWeight: 'bolder', color: '#000000'}}>{order.address}</Typography>} secondary={<Typography sx={{color: '#1f211f', fontWeight: 'bold'}}>Client Address</Typography>}  />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <MessageIcon sx={{color: '#000000'}}/>
                                </ListItemIcon>
                                <ListItemText primary={<Typography sx={{fontWeight: 'bolder', color: '#000000'}}>{order.other_details}</Typography>} secondary={<Typography sx={{color: '#1f211f', fontWeight: 'bold'}}>Extra Information</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </List>
            <Divider sx={{backgroundColor: '#1f211f'}}/>
        <Typography mt={3} sx={{ textAlign: 'center', marginBottom: '8px', fontWeight: 'bold', color: '#000000', fontSize: 18  }} gutterBottom variant="subtitle2" component="div">
            ORDER SUMMARY
        </Typography>
        {JSON.parse(order.content).length > 0 &&
      <Table sx={{ minWidth: '100%',overflow: 'auto' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>Escort</TableCell>
          <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>Rate</TableCell>
          <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {JSON.parse(order.content).map((content) => (
          <TableRow
            key={content.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>{content.name}</TableCell>
            
            <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>
            ${formatPrice(content.unit_price)}
            </TableCell>
            <TableCell align="left" sx={{color: '#000000', fontWeight: 'bolder'}}>
            ${content.quantity === undefined ? formatPrice(content.unit_price): formatPrice(content.unit_price * content.quantity)}
                </TableCell>

          </TableRow>
        ))}

        {/* <TableRow>
        <TableCell align="left" rowSpan={1} >
                      
        </TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
      }
        <Divider sx={{backgroundColor: '#1f211f'}} mt={4}/>
        <Typography mt={3}  sx={{marginLeft: 2, fontWeight: 'bolder', color: '#000000', fontSize: 18, textAlign: 'left'  }} gutterBottom variant="subtitle2" component="div">
            Total Amount Paid: ${formatPrice(order.amount)}
        </Typography>
        <Typography  sx={{marginLeft: 2, fontWeight: 'bold', color: '#000000', fontSize: 14, textAlign: 'left'  }} gutterBottom variant="subtitle2" component="div">
            Date: {getDate(order.created_at)}
        </Typography>

        <Divider sx={{backgroundColor: '#1f211f'}} mt={4}/>
        <Typography mt={3}  sx={{marginLeft: 2, fontWeight: 'bolder', color: '#000000', fontSize: 18, textAlign: 'center'  }} gutterBottom variant="subtitle2" component="div">
            THANK YOU FOR YOUR PATRONAGE 🥰 
        </Typography>

      </CardContent>
        </Card>
        }
    </div>
  )
})

